import { lazy } from 'react';
import { DASHBOARD, LOGIN } from '../constants/routePaths';
import MinimalLayout from '../layouts/MinimalLayout';
import { Navigate } from 'react-router-dom';
import  Loadable from '../components/Loadable';
import {  useIsAuthenticated } from '@azure/msal-react';
const Login = Loadable(lazy(() => import('../pages/Login')));

const AuthRoutes = () => {
    
    const isAuthenticated = useIsAuthenticated();
    return {
        path: '/',
        element: !isAuthenticated ? <MinimalLayout /> : <Navigate to={DASHBOARD} />,
        children: [
            // {
            //     path: '',
            //     element: <Navigate to="/login" />
            // },
            {
                path: LOGIN,
                element: <Login />
            }
        ]
    };
};

export default AuthRoutes;