import { LogLevel } from '@azure/msal-browser';
const clientId = process.env.REACT_APP_auth_clientId;
const auth_authority = process.env.REACT_APP_auth_authority;
const redirectUri = process.env.REACT_APP_auth_redirectUri;
export const msalConfig = {
  auth: {
    clientId: clientId,//'da66d4c9-895d-49e7-9245-802b04894088',
    authority:auth_authority,//'https://login.microsoftonline.com/ffeebe53-4714-40e9-81b1-cb5984a2ddfd',
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) return;
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
            break;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};
