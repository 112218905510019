import { lazy } from 'react';
import Loadable from '../components/Loadable';
import MainLayout from '../layouts/MainLayout';
import { DASHBOARD, LOGIN, PROFILE, WEBSITE_CONFIGURATOR, TAXONOMY_CONFIGURATOR, LOOKUP_CONFIGURATOR } from '../constants/routePaths';
import { Navigate } from 'react-router-dom';
import {  useIsAuthenticated } from '@azure/msal-react';
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));

const Profile = Loadable(lazy(() => import('../pages/Profile')));

const WebsiteConfigurator = Loadable(lazy(() => import('../pages/Configurator/Website')));
const TaxonomyConfigurator = Loadable(lazy(() => import('../pages/Configurator/Taxonomy')));
const LookupConfigurator = Loadable(lazy(() => import('../pages/Configurator/Lookup')));

const MainRoutes = () => {
    
    const isAuthenticated = useIsAuthenticated();
    return {
        path: '/',
        element: isAuthenticated ? <MainLayout /> : <Navigate to={LOGIN} />,
        //element: <MainLayout />,
        children: [
            // {
            //     path: '',
            //     element: <Navigate to={LOGIN} />
            // },
            {
                path: DASHBOARD,
                element: <Dashboard />
            },
            {
                path: PROFILE,
                element: <Profile />
            },
            {
                path: WEBSITE_CONFIGURATOR,
                element: <WebsiteConfigurator />
            },
            {
                path: TAXONOMY_CONFIGURATOR,
                element: <TaxonomyConfigurator />
            },
            {
                path: LOOKUP_CONFIGURATOR,
                element: <LookupConfigurator />
            }
        ]
    };
};
export default MainRoutes;