import { HashRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import App from './App';
//import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
const root = ReactDOM.createRoot(document.getElementById('root'));
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <MsalProvider instance={msalInstance}>
  <HashRouter>
    <App />
  </HashRouter> </MsalProvider>
);
