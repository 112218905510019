import { Button, Navbar, Nav } from 'react-bootstrap';
import {  Link, useNavigate } from 'react-router-dom';
import logo from '../../../assets/idx-logo.png';
import { useMsal,useAccount  } from '@azure/msal-react';
const Header = () => {

    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const handleLogout = () => {
        instance.logoutPopup().catch(e => {
            console.error(e);
          });
          
        navigate('/login');
    }

    return (
        <Navbar expand="lg" className="p-3">
            {/* <Container> */}
            <img src={logo} className="logo" alt="logo" />
            <Link to="/dashboard" className="navbar-brand">Client 360</Link>
            <Navbar.Toggle aria-controls="navbarSupportedContent" />
            <Navbar.Collapse id="navbarSupportedContent">
                <Nav className="me-auto mb-2 mb-lg-0">
                    {/* <Nav.Item>
                        <NavLink to="/dashboard" className="nav-link">Dashboard</NavLink>
                    </Nav.Item> */}

                    {/* <NavDropdown title="Configurator" id="configurator-dropdown">
                        <NavLink to="/website-configurator" className="nav-link">Website</NavLink>
                         <NavLink to="/lookup-configurator" className="nav-link">Look up values</NavLink>
                        <NavLink to="/taxonomy-configurator" className="nav-link">Taxonomy</NavLink>  
                    </NavDropdown> */}
                    {/* <Nav.Item>
                        <NavLink to="/profile" className="nav-link">Profile</NavLink>
                    </Nav.Item> */}
                    {/* <Nav.Item>
                        <NavLink to="/website-configurator" className="nav-link">Website Configurator</NavLink>
                    </Nav.Item> */}
                </Nav>
                { <Nav className="mb-2 mb-lg-0">
                    <Nav.Item>
                        <span className="d-inline-block me-3 header-profile-label">Welcome, {account.name}{" "}</span><Button variant="secondary" onClick={handleLogout}>Logout</Button>
                    </Nav.Item>
                </Nav> }
            </Navbar.Collapse>
            {/* </Container> */}
        </Navbar>
    );
};

export default Header;