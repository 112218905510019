import './App.scss';
import './assets/scss/custom.scss';
import Routes from './routes';

function App() {

  
  return (
    <Routes />  
  );
}

export default App;
